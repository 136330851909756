export default [
  "Squeaky Squirrel",
  "Pathetic Rat",
  "Emma von Eisenhower",
  "Long Ear",
  "Big Chipmunk",
  "Defect Rat",
  "Baby Chipmunk",
  "Deaf Rat",
  "Skinny Tail",
  "Normal Rat",
  "Twisted Paw",
  "Teapot Rat",
];

export default {
  "Squeaky Squirrel": {
    Body: "128,98,134,98,134,102,152,102,187,100,215,94,236,100,242,113,258,139,252,155,243,180,229,192,215,194,215,194,199,183,187,156,168,162,152,182,122,191,98,185,100,175,119,173,137,154,117,151",
    Tail: "261,134,241,104,220,78,211,53,214,40,225,25,240,17,260,8,280,13,291,23,282,41,266,49,267,58,281,88,280,115,265,132",
  },
  "Pathetic Rat": {
    Body: "153,153,156,146,140,132,120,126,181,92,189,97,208,98,223,97,234,103,239,109,238,117,241,144,225,164,208,163,200,154,191,156,192,176,170,197,141,198,144,188,159,182,167,164,152,173",
    Tail: "188,93,179,86,171,75,166,56,166,36,178,20,197,7,216,8,223,24,217,42,235,62,244,85,239,102,223,92,193,94",
  },
  "Emma von Eisenhower": {
    Body: "197,144,194,163,185,187,175,199,138,199,139,194,156,186,164,164,148,165,148,144,142,121,127,107,159,94,170,84,192,73,206,77,233,70,248,73,259,82,261,94,264,119,255,133,241,149,233,153,221,144,208,142",
    Tail: "188,68,172,43,175,9,178,1,234,1,229,13,262,41,269,64,260,81,245,72,234,66,202,75",
  },
  "Long Ear": {
    Body: "182,154,161,175,139,184,117,186,115,173,115,169,133,165,141,158,133,156,141,140,148,121,136,115,136,102,146,99,147,99,147,101,156,102,168,98,181,98,191,93,204,96,230,105,241,107,256,124,257,140,248,163,249,174,242,182,231,175,216,173,198,156",
    Tail: "202,92,192,80,180,58,190,35,206,10,232,10,242,15,241,36,248,53,261,67,278,97,265,126,260,123,240,104,205,94",
  },
  "Big Chipmunk": {
    Body: "131,125,96,129,85,127,65,118,57,100,64,85,89,71,100,66,110,63,121,59,130,55,136,60,134,66,142,70,182,55,215,53,244,68,260,91,259,107,308,137,330,158,343,170,333,179,283,159,248,127,236,132,231,143,219,151,200,154,191,146,193,140,196,138,157,134,142,147,124,152,111,149,108,144,111,138,129,127",
    Tail: "131,125,96,129,85,127,65,118,57,100,64,85,89,71,100,66,110,63,121,59,130,55,136,60,134,66,142,70,182,55,215,53,244,68,260,91,259,107,308,137,330,158,343,170,333,179,283,159,248,127,236,132,231,143,219,151,200,154,191,146,193,140,196,138,157,134,142,147,124,152,111,149,108,144,111,138,129,127",
  },
  "Defect Rat": {
    Body: "194,93,206,71,241,95,213,122,159,100,205,73",
    Tail: "230,68,198,67,183,47,188,2,246,0,250,10,245,18,267,48,265,76,261,83,254,92,249,79",
  },
  "Baby Chipmunk": {
    Body: "164,131,127,142,109,134,106,120,115,107,135,90,131,85,139,80,146,85,159,83,170,74,191,73,208,76,218,85,218,87,234,94,263,97,268,96,267,107,255,110,234,111,222,109,217,138,209,140,204,123,188,127,181,153,171,154,167,147,164,131",
    Tail: "164,131,127,142,109,134,106,120,115,107,135,90,131,85,139,80,146,85,159,83,170,74,191,73,208,76,218,85,218,87,234,94,263,97,268,96,267,107,255,110,234,111,222,109,217,138,209,140,204,123,188,127,181,153,171,154,167,147,164,131",
  },
  "Deaf Rat": {
    Body: "208,130,202,137,203,159,197,173,178,166,174,148,181,133,161,128,144,117,183,79,199,75,227,82,235,113,227,137,220,141,212,137",
    Tail: "182,77,161,65,149,36,160,20,171,11,183,5,199,22,200,36,226,53,235,75,231,85,215,74,187,70",
  },
  "Skinny Tail": {
    Body: "134,43,154,40,175,31,199,24,218,23,237,26,255,32,271,48,278,61,280,75,273,108,269,113,275,122,264,136,248,135,244,124,217,107,192,107,163,137,133,147,119,137,125,125,138,121,145,105,131,109,142,72,149,61,136,51",
    Tail: "280,63,295,47,301,19,288,1,235,1,215,10,198,19,200,21,247,24,266,36",
  },
  "Normal Rat": {
    Body: "120,186,104,186,99,179,101,171,115,169,121,154,110,151,110,102,171,88,180,88,233,116,231,132,222,151,219,166,222,180,213,186,200,183,195,170,170,156,159,156,148,176,133,185,120,188",
    Tail: "181,88,170,84,154,53,164,30,182,9,205,2,221,9,238,21,246,36,242,49,229,54,239,66,254,84,253,100,249,119,240,122,226,108,186,90",
  },
  "Twisted Paw": {
    Body: "129,84,140,82,165,80,184,69,200,68,225,87,236,95,238,108,244,121,231,169,210,168,210,150,205,145,198,168,168,192,164,199,143,197,136,187,132,180,126,172,129,160,126,152,140,147,156,132,166,112,169,91,150,98",
    Tail: "241,107,234,87,205,69,200,65,185,42,264,35,251,43,247,57,253,87,247,105",
  },
  "Teapot Rat": {
    Body: "140,31,144,27,149,27,153,35,161,41,168,51,171,59,172,51,180,45,193,38,199,34,202,35,211,33,215,32,214,34,220,31,216,35,225,37,234,41,236,46,242,52,243,56,244,67,241,75,238,79,237,91,233,91,235,121,233,145,225,160,212,170,204,173,188,173,180,183,169,193,160,198,152,199,131,199,131,190,138,185,131,168,124,159,115,156,115,150,121,146,122,121,115,115,107,114,103,110,98,107,89,105,85,92,91,81,99,81,103,79,100,75,96,70,95,64,101,57,112,46,125,38",
    Tail: "0,0,0,0",
  },
};
